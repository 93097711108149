import React from 'react'
import styled from 'styled-components'
import google from "../../assets/google-play-badge.png"
import miphone from "../../assets/miphone.png"
import appstore from "../../assets/appstore-logo.png"
import Mi from "../../assets/mi.svg"
import ScrollAnimation from 'react-animate-on-scroll';
import user from "../../assets/user-circle.png";

export const Imgstyled = styled.img`
    height: 60px;
    margin: 5% 0%;
    align-self: flex-start;
`

export const ContainerHeader = styled.div`
    width: 90vw;
    height: 0px;
    display: flex;
    justify-content: flex-end;
    margin: 2% 0%;
    
    @media (max-width: 850px) {
        display: none;
    }
`

export const Button = styled.a`
    background: #fff;
    color: #30735f;
    text-align: center;
    font-weight: 500;
    font-size: 18px;
    height: 40px;
    width: 150px;
    :hover{
        transform: scale(1.05);
        cursor: pointer;
    }
`
export const ImagenStyled = styled.img`
    
    max-width: 450px;

    @media (max-width: 420px) {
        max-width: inherit;
    }

`

export const Span = styled.label`
   margin: 0% 10%;
`

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true
        }
    }

    componentDidMount() {
        this.setState({ isLoading: false })
    }

    componentWillUnmount() {
        if (this.timerHandle) {
            clearTimeout(this.timerHandle);
            this.timerHandle = 0;
        }
    }

    render() {
        return (
            <>
                <ContainerHeader>
                    <Button target="_blank" href="http://admin.miiii.app">
                        <img src={user} style={{ verticalAlign: "bottom" }} />
                        <Span>Ingresar</Span>
                    </Button>
                </ContainerHeader>
                <div class="containerwelcome">
                    <div class="first column welcome">
                        <Imgstyled src={Mi} align="left" />
                        <div class="first column title">La plataforma digital de gestión de servicios, dentro de tu comunidad, barrio o consorcio.</div>
                        <div class="first column subtitle">Es gratuita, simple de usar y segura.</div>
                        <div class="first column label">Descargala gratis acá:</div>
                        <div class="containerlogoStore">
                            <a target="_blank" href="https://play.google.com/store/apps/details?id=app.miiii.mibarrioapp" style={{ marginRight: "5%" }}>
                                <img src={google} style={{ maxWidth: "155px", height: "50px" }} />
                            </a>
                            <a target="_blank" href="https://apps.apple.com/ar/app/miiii/id1460141483?l=en" >
                                <img src={appstore} style={{ maxWidth: "155px", height: "50px" }} />
                            </a>
                        </div>
                    </div>
                    <div class="second column welcome">
                        <ScrollAnimation animateIn='slideInRight' animateOnce={true}>
                            <ImagenStyled src={miphone} />
                        </ScrollAnimation>
                    </div>
                    <div class="first column label responsive">Descargala gratis acá:</div>
                    <div class="containerlogoStore responsive">
                        <a target="_blank" href="https://play.google.com/store/apps/details?id=app.miiii.mibarrioapp">
                            <img src={google} style={{ maxWidth: "50vw", height: "50px", marginRight: "6%", marginLeft: "-3%" }} />
                        </a>
                        <a target="_blank" href="https://apps.apple.com/ar/app/miiii/id1460141483?l=en" >
                            <img src={appstore} style={{ maxWidth: "50vw", height: "50px" }} />
                        </a>
                    </div>
                </div>
            </>
        )
    }
}


export default Header
