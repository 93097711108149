import React from 'react'
import styled from 'styled-components'
import celu1erSlide from "../../assets/celu1erSlide.png"
import celu2doSlide from "../../assets/celu2doSlide.png"
import checked from "../../assets/checked.png"
import shield from "../../assets/shield.png"
import mastercard from "../../assets/mastercard.png"
import macro from "../../assets/macroBMA.jpg"
import ScrollAnimation from 'react-animate-on-scroll';



const Container = styled.div`
    width: 90%;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    margin-left: 5%;
    margin-top: ${(props) => props.marginTop ? props.marginTop : ""};
    background-color: #F9FCF5;
    padding: 2% 2%;
    @media (max-width: 850px) {
        flex-direction: column;
        margin: 0% 2%;
        padding: 0%;
        width: 95vw;
    }
`

const ColumnContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;

    @media (max-width: 850px) {
        display: flex;
        flex-direction: ${(props) => props.column ? props.column : "column-reverse"};
        width: 98%;
        margin: 0% 2%;
    }
`
const ColumnOne = styled.div`
    width: 45%;
    padding: 0% 8%;
    margin: ${(props) => props.margin ? props.margin : ""};
    display: flex;
    flex-direction: column;
    @media (max-width: 850px) {
        width: 100%;
        margin: 0%;
        padding: 0%;
    }
`
const ColumnTwo = styled.div`
    width: ${(props) => props.width ? props.width : "30%"};
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    margin: ${(props) => props.margin ? props.margin : ""};
    @media (max-width: 850px) {
        width: 50%;
        align-self: center;
        right: 1%;
        margin-top: 10%;
        position: relative;
    }
`
const ContainerImg = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    height: 60px;
    width: 35%;
    margin: 5% 3%;

    @media (max-width: 850px) {
        justify-content: space-evenly;
        height: 60px;
        margin: 8% 0%;
        width: 100%;
    }
`
const ImgPhone = styled.img`
    z-index: 2;
    width: ${(props) => props.width ? props.width : "80%"};
    margin: 0% 10%;
    
    @media (max-width: 850px) {
        width: 95%;
        margin: 0%;
    }
`
const SecondImageContainer = styled.div`
    z-index: 2;
    width: 40%;
    @media (max-width: 850px) {
        width: 85%;
        left: 5%;
        position: relative;
        margin-top: 50px;
    }
`
const Checked = styled.img`
    max-width: 25px;
`
const CheckedContainer = styled.div`
    @media (max-width: 850px) {
        width: 40px;
        overflow: visible !important;
    }
`
const Shield = styled.img`
    max-width: 43px;
    max-height: 51px;
    @media (max-width: 850px) {
        top: 10%;
        left: 25%;
        position: absolute;
    }
`
const ContainerShield = styled.div`
    width: 200px;
    @media (max-width: 850px) {
        width: 40px; 
        position: relative;
    }
`
const TitleDescription = styled.div`
    width: 100%;
    height: ${(props) => props.height ? props.height : "50px"};
    font-size: ${(props) => props.size ? props.size : "35px"};
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.39;
    letter-spacing: normal;
    text-align: initial;
    color: ${(props) => props.color ? props.color : " #182C45"};
    margin: ${(props) => props.margin ? props.margin : ""};

    @media (max-width: 850px) {
        height: ${(props) => props.heightResponsive ? props.heightResponsive : "80px"};
        font-size: ${(props) => props.size ? props.size : "30px"};
        color: ${(props) => props.color ? props.color : " #182C45"};
        font-weight: 500;
        margin: 4% 0%;
    }
`
const LabelDescription = styled.div`
    width: 100%;
    font-size: 18px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: initial;
    color: #4a4a4a;
    margin: ${(props) => props.margin ? props.margin : ""};
    
    @media (max-width: 850px) {
        margin: 0px;
        font-weight: 400;
        font-size: 16px;
    }
`
const List = styled.div`  
    width: ${(props) => props.width ? props.width : "40vw"};
    height: 10px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.39;
    letter-spacing: normal;
    color: #000000;
    font-size: ${(props) => props.size ? props.size : "16px"};
    margin: ${(props) => props.margin ? props.margin : "3% 0%"};
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media (max-width: 850px) {
        height: auto;
        width: 95vw;
        margin: ${(props) => props.margin ? props.margin : "3% 0%"};
    }
`
const TYC = styled.a`  
    color: #30735F;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 27px;
    text-align: left; 
    &:hover{
       cursor: pointer;
    }     

`
const Linea = styled.div`  
    width: 90%;
    height: 1px;
    opacity: 0.6;
    background-color: #e6e6e6;
`
const ContainerDescriptionFinal = styled.div`  
    
    @media (max-width: 850px) {
    width: 80%;
    }
`
class muchoMas extends React.Component {
    render() {
        return (
            <>
                <Container marginTop={"10%"}>
                    <ScrollAnimation
                        animateIn='bounceInLeft'
                        animateOnce={true}>
                        <ColumnContainer >
                            <ColumnOne>
                                <TitleDescription height={"auto"}>Mucho más que una billetera virtual</TitleDescription>
                                <LabelDescription margin={"0% 0% 5% 0%"} >Además de todos los beneficios, la app cuenta con una billetera virtual con la que vas a poder pagar todos tus servicios, los proveedores, realizar compras y mucho más.</LabelDescription>
                                <ul>
                                    <Linea />
                                    <List>
                                        <div>Pago de expensas</div>
                                        <CheckedContainer>
                                            <ScrollAnimation
                                                animateIn="bounce"
                                                duration={2} initiallyVisible={true}
                                                delay={500}
                                                animateOnce={true}
                                            >
                                                <Checked src={checked} />
                                            </ScrollAnimation>
                                        </CheckedContainer>
                                    </List>
                                    <Linea />
                                    <List>
                                        <div>Pago de servicios</div>
                                        <div>
                                            <ScrollAnimation
                                                animateIn="bounce"
                                                duration={2} initiallyVisible={true}
                                                delay={800}
                                                animateOnce={true}
                                            >
                                                <CheckedContainer>
                                                    <Checked src={checked} />
                                                </CheckedContainer>
                                            </ScrollAnimation>
                                        </div>
                                    </List>
                                    <Linea />
                                    <List>
                                        <div>Cobrar y Pagar por código QR </div>
                                        <div>
                                            <ScrollAnimation
                                                animateIn="bounce"
                                                duration={2} initiallyVisible={true}
                                                delay={1200}
                                                animateOnce={true}
                                            >
                                                <CheckedContainer>
                                                    <Checked src={checked} />
                                                </CheckedContainer>
                                            </ScrollAnimation>
                                        </div>
                                    </List>
                                    <Linea />
                                    <List>
                                        <div>Billeteras para todo tu grupo familiar</div>
                                        <div>
                                            <ScrollAnimation
                                                animateIn="bounce"
                                                duration={2} initiallyVisible={true}
                                                delay={1500}
                                                animateOnce={true}
                                            >
                                                <CheckedContainer>
                                                    <Checked src={checked} />
                                                </CheckedContainer>
                                            </ScrollAnimation>
                                        </div>
                                    </List>
                                    <Linea />
                                    <List width={"auto"} size={"15px"} >
                                        <ContainerShield><Shield src={shield} /></ContainerShield>
                                        <ContainerDescriptionFinal>
                                            Para nosotros tu seguridad es muy importante. Es por eso que miiii protege tu información, encriptando tus datos de forma segura, cumpliendo con los más altos estándares de seguridad online.
                                        </ContainerDescriptionFinal>
                                    </List>

                                </ul>
                            </ColumnOne>
                            <ColumnTwo>
                                <ImgPhone src={celu1erSlide} />
                            </ColumnTwo>
                        </ColumnContainer>
                    </ScrollAnimation>
                </Container>
                <Container>
                    <ScrollAnimation
                        animateIn='bounceInRight '
                        animateOnce={true}>
                        <ColumnContainer column={"column"}>
                            <SecondImageContainer >
                                <ImgPhone width={"100%"} src={celu2doSlide} />
                            </SecondImageContainer>
                            <ColumnOne margin={"2% 0% 0% 4%"}>
                                <TitleDescription height={"80px"}>El respaldo de Banco Macro BMA</TitleDescription>
                                <LabelDescription margin={"10% 0% 5% 0%"}>
                                    Con el respaldo de banco Macro BMA, si adherís tus expensas al débito en la cuenta Miiii Macro BMA vas a poder disfrutar de los beneficios:
                                </LabelDescription>
                                <TitleDescription color={"#30735f"} heightResponsive={"45px"} size={"24px"} >Te devolvemos hasta $80.000 en tus expensas(1)</TitleDescription>
                                <LabelDescription margin={"5% 0% 0% 0%"} >Y además accedés a:</LabelDescription>
                                <ul>
                                    <List >
                                        <div>Cuenta Macro BMA Selecta sin cargo de mantenimiento (2)</div>
                                        <div>
                                            <ScrollAnimation
                                                animateIn="bounce"
                                                duration={2} initiallyVisible={true}
                                                delay={500}
                                                animateOnce={true}
                                            >
                                                <CheckedContainer>                                             <Checked src={checked} />                                             </CheckedContainer>
                                            </ScrollAnimation>
                                        </div>
                                    </List>
                                    <Linea />
                                    <List>
                                        <div>Tarjeta Mastercard Platinum o Black sin costo de renovación (3)</div>
                                        <div>
                                            <ScrollAnimation
                                                animateIn="bounce"
                                                duration={2} initiallyVisible={true}
                                                delay={800}
                                                animateOnce={true}
                                            >
                                                <CheckedContainer>                                             <Checked src={checked} />                                             </CheckedContainer>
                                            </ScrollAnimation> </div>
                                    </List>
                                    <Linea />
                                    <List>
                                        <div>Descuentos especiales en compras y salidas en los comercios cercanos a tu comunidad</div>
                                        <div>
                                            <ScrollAnimation
                                                animateIn="bounce"
                                                duration={2} initiallyVisible={true}
                                                delay={1200}
                                                animateOnce={true}
                                            >
                                                <CheckedContainer>                                      <Checked src={checked} />               </CheckedContainer>
                                            </ScrollAnimation>
                                        </div>
                                    </List>
                                    <Linea />
                                    <List>
                                        <div>Atención personalizada a cargo de un ejecutivo de relacionamiento</div>
                                        <div>
                                            <ScrollAnimation
                                                animateIn="bounce"
                                                duration={2} initiallyVisible={true}
                                                delay={1500}
                                                animateOnce={true}
                                            >
                                                <CheckedContainer>
                                                    <Checked src={checked} />
                                                </CheckedContainer>
                                            </ScrollAnimation>
                                        </div>
                                    </List>
                                    <Linea />
                                    <List>
                                        <div>Acceso a eventos exclusivos </div>
                                        <div>
                                            <ScrollAnimation
                                                animateIn="bounce"
                                                duration={2} initiallyVisible={true}
                                                delay={1500}
                                                animateOnce={true}
                                            >
                                                <CheckedContainer>
                                                    <Checked src={checked} />
                                                </CheckedContainer>
                                            </ScrollAnimation>
                                        </div>
                                    </List>
                                    <Linea />
                                    <List>
                                        <div>Todas tus compras y débitos suman más puntos en el programa de recompensas @tumundo (4)</div><div>
                                            <ScrollAnimation
                                                animateIn="bounce"
                                                duration={2} initiallyVisible={true}
                                                delay={1800}
                                                animateOnce={true}
                                            >
                                                <CheckedContainer>                                             <Checked src={checked} />                                             </CheckedContainer>
                                            </ScrollAnimation>
                                        </div>
                                    </List>
                                    <Linea style={{ marginTop: "35px" }} />
                                    <List>
                                        <TYC target="_blank" href="https://www.miiii.app/TyC_Cartera_Consumo.html" >Términos y Condiciones </TYC>
                                    </List>
                                </ul>
                                <ContainerImg>
                                    <img src={macro} />
                                    <img src={mastercard} />
                                </ContainerImg>
                            </ColumnOne>
                        </ColumnContainer>
                    </ScrollAnimation>
                </Container>
            </>
        )
    }
}

export default muchoMas
