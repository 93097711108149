import React from 'react'
import styled from 'styled-components'
import splash from "../assets/splash-video.png"
import play from "../assets/play.png"
import Vimeo from '@u-wave/react-vimeo';
import ScrollAnimation from 'react-animate-on-scroll';
import $ from "jquery"
 

export const Imgstyled = styled.img`
    height: 60px;
    margin: 5% 0%;
    align-self: flex-start;
`

export const ContainerVideo = styled.div`
    position: relative;
    align-items: center;
    padding: 0% 4%;
    
`

export const ContainerIframe = styled.div`
    margin: 0 auto;
`


class Video extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            active: false
        }
        this.handleClick = this.handleClick.bind(this);
      }
    
      componentWillUnmount(){
        if (this.timerHandle) {
          clearTimeout(this.timerHandle);
          this.timerHandle = 0;
        }
      }     
    
    

    fullScreen(){
        var video = $(".player");
        var vid = video[0];
        vid.play();
        if (vid.requestFullscreen) {
          vid.requestFullscreen();
        } else if (vid.mozRequestFullScreen) {
          vid.mozRequestFullScreen();
        } else if (vid.webkitRequestFullscreen) {
          vid.webkitRequestFullscreen();
        }
    }

    handleClick(){
      this.setState(prevState => ({
        active: !prevState.active
      }))
    }

    render() {
        return (
            <ScrollAnimation 
                animateIn='bounceInRight'
                animateOnce={true}>
            <ContainerVideo >
                <p  style={this.state.active ? {display: "none"}:{display: "unset"}} >
                    <img  src={splash}/>
                </p> 
                <img 
                    style={this.state.active ? {display: "none"}:{display: "unset"}} onClick={this.handleClick} 
                    src={play} 
                    id="videoplay"
                />
                <ContainerIframe 
                    style={this.state.active ? {display: "unset"}:{display: "none"}}
                >
                    <Vimeo
                        className={ 
                            this.state.active ? "slideUp" : "" 
                        }
                        video="356262732"
                        width="auto"
                        showTitle={false}
                        showPortrait={false}
                        showByline={false}
                        controls={false}
                        background={true}
                        responsive={true}
                        onPlay={()=> this.fullScreen()}
                        
                    />
                </ContainerIframe>
            </ContainerVideo> 
            </ScrollAnimation > 
        )
    }
}


export default Video
