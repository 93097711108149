import React from 'react';
import './App.css';
import './Responsive.css';
import styled from 'styled-components'
import Header from "./containers/header"
import Video from "./containers/video"
import MuchoMas from "./containers/billetera/muchoMas.js"
import GroupImage from "./containers/groupImage"
import AdminWhoAre from "./containers/dataWhoAre/admin"
import PropietaryWhoAre from "./containers/dataWhoAre/propietary"
import ComercioWhoAre from "./containers/dataWhoAre/comercio"
import SupplierWhoAre from "./containers/dataWhoAre/supplier"
import FormMaterialUI from "./containers/form/"
import Footer from "./containers/footer"
import TrustUs from "./containers/trustUs"
import Loader from "./components/loader"


export const Container = styled.div`
`


const config = {
  api: `${process.env.REACT_APP_API}`,
  title: 'Contactarmee',
  successMessage: 'Gracias por el contacto, a la brevedad nos pondremos en contacto contigo.',
  errorMessage: 'Sorry we have some problems.',
  fields: {
    firstName: '',
    lastName: '',
    email: '',
    msg: ''
  },
  fieldsConfig: [
    { id: 1, label: 'First Name', fieldName: 'firstName', type: 'text', placeholder: 'Your First Name', isRequired: true, klassName: 'first-name-field' },
    { id: 2, label: 'Last Name', fieldName: 'lastName', type: 'text', placeholder: 'Your Last Name', isRequired: true, klassName: 'last-name-field' },
    { id: 3, label: 'Email', fieldName: 'email', type: 'email', placeholder: ' Your Email', isRequired: true, klassName: 'email-field' },
    { id: 4, label: 'Message', fieldName: 'msg', type: 'textarea', placeholder: 'Write something.....', isRequired: true, klassName: 'message-field' }
  ]
}

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      moduleToShow: "admin",
      isLoading: true
    }
  }

  handleHover(module) {
    this.setState({ moduleToShow: module })
  }

  componentDidMount() {
    this.timerHandle = setTimeout(() => this.setState({ isLoading: false }), 2300);
  }

  componentWillUnmount() {
    if (this.timerHandle) {
      clearTimeout(this.timerHandle);
      this.timerHandle = 0;
    }
  }

  yourPage() {
    return (
      <>
        <Header />
        <Video />
        <GroupImage handleHover={(module) => this.handleHover(module)} />
        {(() => {
          switch (this.state.moduleToShow) {
            case 'admin':
              return <AdminWhoAre />;
            case 'propietario':
              return <PropietaryWhoAre />;
            case 'proveedor':
              return <SupplierWhoAre />;
            case 'comercio':
              return <ComercioWhoAre />;
            default:
              return null;
          }
        })()}
        <MuchoMas />
        <TrustUs />
        <FormMaterialUI config={config} />
        <Footer />
      </>
    )
  }

  render() {
    return (
      <>
        {this.state.isLoading ? <Loader /> : ""}
        <Container isLoading={this.state.isLoading} >
          <>{this.state.isLoading ? "" : this.yourPage()}</>
        </Container>
      </>
    );
  }
}

export default App;
