import React from 'react'
import styled from 'styled-components'
import './carousel.css';
import arrow from "../../../assets/right-arrow.svg"
import iphone from "../../../assets/iphone.svg"
import billetera from "../../../assets/billetera2.png"
import servicios from "../../../assets/servicios2.png"
import accesos from "../../../assets/acceso2.png"
import $ from 'jquery';
import celular from "../../../assets/celu.png"
import { Animated } from "react-animated-css";


const Container = styled.div`
    width:  ${(props) => props.widthLimit ? "90%" : "100%"};
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    margin: ${(props) => props.margin ? props.margin : "3% 0%"}; 
    
    @media (max-width: 850px) {
        flex-direction: column;
        width: 100%;
        margin-bottom: 25%;
        margin: 0px ; 
        position: ${(props) => props.relative ? "relative" : ""}; 
    }
`

const ContainerCol1 = styled.div`
    width:  ${(props) => props.widthLimit ? "90%" : "100%"};
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    margin: ${(props) => props.margin ? props.margin : "3% 0%"}; 
    
    @media (max-width: 850px) {
        display: none;
    }
`

const TitleDescriptionNOCSS = styled.div`
    width: 100%;
    height: 50px;
    font-size: 30px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.39;
    letter-spacing: normal;
    text-align: initial;
    cursor: alias;
    color: ${(props) => props.color ? props.color : " #30735f"};
    
    @media (max-width: 850px) {
        margin-bottom: 40px;
        text-align: center;    
    }
`
const ColumnTwoDescrip = styled.div`
    width: 100%;
    padding-top: 5%;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column; 
    @media (max-width: 850px) {
        width: 90%;
        margin: 0% 5%; 
        margin-bottom: 14%;
    }
`
const Img = styled.img`    
    z-index: 10000;
    width: 60%;
    margin-left: 20%;

     @media (max-width: 850px) {
        right: 1%;
        max-width: 16px;
        position: absolute;
        top: 20%;
        max-width: 20px;
    }

`

const TitleDescription = styled.div`
    width: 100%;
    height: 50px;
    font-size: 30px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.39;
    letter-spacing: normal;
    text-align: initial;
    color: ${(props) => props.color ? props.color : " #30735f"};
    border-left: 3px solid #30735f;
    padding-left: 12px;
    cursor: alias;
    @media (max-width: 850px) {
        height: auto;
    }
`
const LabelDescription = styled.div`
    width: 100%;
    height: 90px;
    font-size: 18px;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: initial;
    color: #4a4a4a;
    border-left: 3px solid #30735f;
    padding-left: 12px;
    margin-bottom: 20px; 
    @media (max-width: 850px) {
       
       height: auto;
    }
`

function moveToSelected(element) {

    if (element == "next") {
        var selected = $(".selected").next();
    } else if (element == "prev") {
        var selected = $(".selected").prev();
    }

    var next = $(selected).next();
    var prev = $(selected).prev();
    var prevSecond = $(prev).prev();
    var nextSecond = $(next).next();

    $(selected).removeClass().addClass("selected");

    $(prev).removeClass().addClass("prev");
    $(next).removeClass().addClass("next");

    $(nextSecond).removeClass().addClass("nextRightSecond");
    $(prevSecond).removeClass().addClass("prevLeftSecond");

    $(nextSecond).nextAll().removeClass().addClass('hideRight');
    $(prevSecond).prevAll().removeClass().addClass('hideLeft');

}

$('#carousel div').click(function () {
    moveToSelected($(this));
});



class dataWhoAre extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            imageSlide: 1
        }
        this.state = {
            imageSlide: 2,
            txt1: true,
            txt2: false,
            txt3: false,
            txt4: false,
            txt5: false,
            txt6: false,

        }
        this.escFunction = this.escFunction.bind(this);
    }

    escFunction(event) {
        if (event.keyCode === 37) {
            this.prev()
        }
        if (event.keyCode === 39) {
            this.next()
        }
    }
    componentDidMount() {
        document.addEventListener("keydown", this.escFunction, false);
    }
    componentWillUnmount() {
        document.removeEventListener("keydown", this.escFunction, false);
    }

    prev = () => {
        if (this.state.imageSlide > 0) {
            this.setState({ imageSlide: this.state.imageSlide - 1 });
            moveToSelected('prev')
        }
    }

    next = () => {
        if (this.state.imageSlide < 2) {
            this.setState({ imageSlide: this.state.imageSlide + 1 });
            moveToSelected('next')
        }
    }

    toggleFalseAllTextos = () => {
        this.setState({
            txt1: false,
            txt2: false,
            txt3: false,
            txt4: false,
            txt5: false,
            txt6: false
        });

    }

    toggle1 = () => {
        this.toggleFalseAllTextos();
        this.setState({ txt1: true });
    }

    toggle2 = () => {
        this.toggleFalseAllTextos();
        this.setState({ txt2: true });
    }

    toggle3 = () => {
        this.toggleFalseAllTextos();
        this.setState({ txt3: true });
    }

    toggle4 = () => {
        this.toggleFalseAllTextos();
        this.setState({ txt4: true });
    }

    toggle5 = () => {
        this.toggleFalseAllTextos();
        this.setState({ txt5: true });
    }

    toggle6 = () => {
        this.toggleFalseAllTextos();
        this.setState({ txt6: true });
    }


    render() {
        return (
            <Container id="aca" widthLimit={true}>
                <ContainerCol1 padding={true} margin={"0% 5%"}>
                    <Img src={celular} onClick={() => this.next()} />
                </ContainerCol1>
                <ColumnTwoDescrip>
                    <TitleDescriptionNOCSS>¿Qué podés hacer con Miiii?</TitleDescriptionNOCSS>
                    <TitleDescription onMouseEnter={() => this.toggle1()}>QR</TitleDescription>
                    {this.state.txt1 ?
                        <Animated animationIn="fadeIn" animationOut="fadeOut" animationInDuration={600} animationOutDuration={400} isVisible={this.state.txtReservas}>
                            <LabelDescription>Exponé en tu local QRs de cobro para vender más simple.</LabelDescription>
                        </Animated>
                        :
                        null
                    }

                    <TitleDescription onMouseEnter={() => this.toggle2()}>Marketplace</TitleDescription>
                    {this.state.txt2 ?
                        <Animated animationIn="fadeIn" animationOut="fadeOut" animationInDuration={600} animationOutDuration={400} isVisible={this.state.txtReservas}>
                            <LabelDescription>Publicá tus productos en la app para vender online a los miembros de la comunidad de la que sos parte o a la que tenés alcance. Seguí los vendidos y enviados, y respondé mensajes y consultas de tus clientes.</LabelDescription>                        </Animated>
                        :
                        null
                    }

                    <TitleDescription onMouseEnter={() => this.toggle3()}>Billetera</TitleDescription>
                    {this.state.txt3 ?
                        <Animated animationIn="fadeIn" animationOut="fadeOut" animationInDuration={600} animationOutDuration={400} isVisible={this.state.txtReservas}>
                            <LabelDescription>Cobrá, generá métricas y pagá a proveedores y servicios. Trasnferencias a cualquier CBU/CVU con cash in y cash out inmediato.</LabelDescription>
                        </Animated>
                        :
                        null
                    }
                </ColumnTwoDescrip>
            </Container>
        )
    }
}

export default dataWhoAre
