import React from 'react'
import styled from 'styled-components'
import ScrollAnimation from 'react-animate-on-scroll';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import { Formik, Field } from 'formik'
import * as Yup from 'yup'
import Input from '../../components/Input'

import {
  FormContainer, Row, RowLeft, InputWrapper, InputLabel,
  ErrorLabel, ErrorMessage, ButtonWrapper, StyledButton, CrossImage, InputWrapperMargin
} from './styled'

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const Container = styled.div`
    width: 100%;
    display: flex;
    clear: both;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: space-around;
    margin-top: 10%;
`
const ContainerForm = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    height: 630px;
    border-radius: 6px;
    margin-top: 6%;  
    @media (max-width: 850px) {
        width:100%;
        margin-left: 0%;  
        margin-top: 50px;  
        height: 630px;
    }
`
const TitleForm = styled.div`
    width: 100%;
    height: 50px;
    font-size: 36px;
    font-weight: bold;
    line-height: 1.39;
    text-align: center;
    color: #30735f;
    @media (max-width: 850px) {
        font-size: 25px;
    }
`
const SubTitleForm = styled.div`
    width: 100%;
    height: 50px;
    font-size: 21px; 
    line-height: 2.38;
    text-align: center;
    color: #4a4a4a;
    margin: 2% 0%;
    @media (max-width: 850px) {
        font-size: 17px; 
        line-height: 1.89;
        height: 80px;
        margin: 2% 0%;
        width: 80%;
        align-self: center;
    }
`
const ContainerInput = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    @media (max-width: 850px) {
        width: 90%;
        height: 50px;
        margin: 0% 5%;
    }
`
const SelectInput = styled.select`
width: 100%;
height: auto;
background: white;

font-size: 14px;
border: none;

width: 100%;
padding: 10px 10px;
box-sizing: border-box;
outline: none;
font-size: 18px;
border-top: 0px;
border-right: 0px;
border-radius: 0px;
border-left: 0px;
border-bottom: 1px solid #eeeeee;

  option {
    color: black;
    background: white;
    display: flex;
    white-space: pre;
    min-height: 20px;
    padding: 0px 2px 1px;
  }
`;
const Button = styled.button`  
    width: 240px;
    height: 40px;
    text-align: center;
    font-size: 20px;
    color: #ffffff;
    border-radius: 6px;
    background-color: #1873f3;
    margin: 3% 35%;
    border: none;
    @media (max-width: 850px) {
        position: relative;
        align-self: center;
        min-height: 50px;
    }
`
const StyledButtonNew = withStyles({
  root: {
    background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    borderRadius: 3,
    border: 0,
    color: 'white',
    height: 48,
    padding: '0 30px',
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
  },
  label: {
    textTransform: 'capitalize',
  },
})(Button);

class FormMaterialUI extends React.Component {

  constructor(props) {
    super(props);
    this.state = { fname: '', lname: '', email: '', message: '', mailSent: false, error: null, sending: false }
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange = (setFieldValue, field, option) => {
    setFieldValue('option', option);
  }

  handleSubmit = async data => {
    this.setState({ sending: !this.state.sending })
    const dataForm = {
      FullName: data.name,
      Type: data.type,
      phonenumber: data.phone,
      email: data.email,
    }


    axios({
      method: 'post',
      url: 'https://7l29jjt214.execute-api.us-east-2.amazonaws.com/Staging/api/person/landing-contact',
      data: dataForm,
      headers: { 'content-type': 'application/json' }
    })
      .then(function (response) {
        if (response.status === 200) { this.setState({ sending: !this.state.sending, mailSent: true }) }

      }.bind(this))
      .catch(function (response) {
        console.log(response);
        this.setState({ sending: !this.state.sending, mailSent: true })
      }.bind(this))
  }

  getFormContent = ({ handleSubmit, values, setFieldValue, errors }) => (
    <FormContainer onSubmit={handleSubmit}>
      <Row>
        <InputWrapper width={48}>
          <InputLabel>{'Nombre y apellido'}</InputLabel>
          <Input
            value={values.name}
            onChange={({ target: { value } }) => setFieldValue('name', value)}
            placeholder={''}
            type={'text'}
            rows={1}
            error={errors.name ? true : false}
          />
          {errors.name && <ErrorLabel>{errors.name}</ErrorLabel>}
        </InputWrapper>
      </Row>
      <Row>
        <InputWrapper>
          <InputLabel>{'Tipo de perfil'}</InputLabel>
          <SelectInput
            value={values.type}
            onChange={({ target: { value } }) => setFieldValue('type', value)}
            placeholder={'Selcciona tu perfil'}
            type={'text'}
            rows={1}
            error={errors.type ? true : false}
          >
            <option value="">Seleccioná un perfil...</option>
            <option value="Administrador">Administrador</option>
            <option value="Proveedor">Proveedor</option>
            <option value="Vecino">Vecino</option>
          </SelectInput>
          {errors.type && <ErrorLabel>{errors.type}</ErrorLabel>}
        </InputWrapper>
      </Row>
      <Row>
        <InputWrapper width={48}>
          <InputLabel>{'Email'}</InputLabel>
          <Input
            value={values.email}
            onChange={({ target: { value } }) => setFieldValue('email', value)}
            placeholder={''}
            type={'text'}
            rows={1}
            error={errors.email ? true : false}
          />
          {errors.email && <ErrorLabel>{errors.email}</ErrorLabel>}
        </InputWrapper>
      </Row>
      <Row>
        <InputWrapper width={48}>
          <InputLabel>{'Telefono'}</InputLabel>
          <Input
            value={values.phone}
            onChange={({ target: { value } }) => setFieldValue('phone', value)}
            placeholder={''}
            type={'text'}
            rows={1}
            error={errors.phone ? true : false}
          />
          {errors.phone && <ErrorLabel>{errors.phone}</ErrorLabel>}
        </InputWrapper>
      </Row>
      {
        this.state.sending ?
          'Enviando mensaje...'
          :
          null
      }
      <ButtonWrapper>
        {
          this.state.mailSent ?
            'Mensaje enviado!'
            :
            <StyledButton type="Submit" onSubmit={() => this.handleSubmit()}>
              Enviar
            </StyledButton>
        }
      </ButtonWrapper>    </FormContainer>
  )

  getSchema = () =>
    Yup.object().shape({
      name: Yup.string()
        .min(3, 'El nombre debe tener un mínimo de 3(tres) caracteres')
        .required('Nombre es requerido'),
      type: Yup.string()
        .required('El tipo de perfil es requerido'),
      email: Yup.string()
        .email('El Email es inválido')
        .required('Email es requerido'),
      phone: Yup.string()
        .matches(phoneRegExp, 'Phone number is not valid')
        .required('El telefono es requerido')
    })

  getInitialValues = () => ({
    neighbourhood: '',
    type: ""
  })

  render() {
    return (
      <Container>
        <ScrollAnimation animateIn='slideInUp' animateOnce={true}>
          <ContainerForm>
            <TitleForm>¿Querés saber más?</TitleForm>
            <SubTitleForm>Completá tus datos y nos contactaremos a la brevedad.</SubTitleForm>
            <Formik
              initialValues={this.getInitialValues()}
              validateOnChange={false}
              validationSchema={this.getSchema()}
              onSubmit={this.handleSubmit}
              render={e => this.getFormContent(e)} />
          </ContainerForm>
        </ScrollAnimation>
      </Container >
    );
  }
}
export default FormMaterialUI;
