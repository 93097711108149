import React from 'react'
import styled, { css } from 'styled-components'

const StyledInput = styled.input`
  width: 100%;
  
  padding: 8px 10px;
  box-sizing: border-box;
  
  outline: none;

`

const Input = props => <StyledInput {...props} />

export default Input
