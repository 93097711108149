import React from 'react'
import styled from 'styled-components'
import { Imgstyled } from '../header';
import $ from "jquery"
import logoNordelta from "../../assets/logoNordelta.png"
import logoPuertos from "../../assets/logoPuertos.png"


export const Title = styled.div`
  width: 100%;
  height: 100px;
  font-size: 36px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.39;
  letter-spacing: normal;
  text-align: center;
  color: #182C45;
  margin-top: 6%;

    @media (max-width: 850px) {
        font-size: 30px;
        height: 50px;
        margin-bottom: 29px;
        margin-top: 30px;
    }
`
export const ImageStyled = styled.img`
    cursor: pointer;  
    margin: ${(props) => props.nordelta ? "2% 2%" : "1% 2%"};    
    height: 100%;
    transition: all 1s ease-in-out;

    &:hover{
        transition: all 0.5s ease-in;
        transform: scale(1.1,1.1) ;
    }     
    @media (max-width: 850px) {
        width: 40%;     
        margin: ${(props) => props.nordelta ? "2% 2.5%" : "0% 2.5%"};   
        height: 100%;
        margin-bottom: 50px;
    }
`
export const Label = styled.div`
    position: absolute;
    bottom: 20px;
    left: 0;
    font-size: 30px;
    font-weight: 500;
    text-align: center;
    color: #ffffff;
    cursor: pointer;

    @media (max-width: 850px) {
        bottom: 40px;
        width: 100%;
        font-size: 20px;
        text-align: center;
        display: contents;
    }

`
export const ContainerTestImage = styled.div`
    text-align: center;
    width: 100%
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: nowrap;
`

export const ContainerImage = styled.div`
    position: relative;
    margin-right: 2%; display: inline-block;
    transition: all 1s  ease-in-out;
    @media (max-width: 850px) {
        margin-bottom: ${(props) => props.margin ? "50px" : ""};
    }
`
export const TextMiddle = styled.div`
    position: absolute;
    width: 70%;
    top: 80%;
    left: ${(props) => props.left};
    text-align: center;
    cursor: pointer;

    &:hover ${Imgstyled}{
        transition: all 0.5s ease-in-out;
        transform: scale(1.1,1.1) ;
    } 
    @media (max-width: 850px) {
        top: 80%;
        width: 100%;
        left: 0px;
        font-size: 20px;
        text-align: center;
    }
`

class groupImage extends React.Component {
    state = {
        active: "admin"
    }

    refreshActive = (active) => {
        this.setState({ active: active })
        this.props.handleHover(active)
        $(document).ready(function () {
            $("html, body").animate({
                scrollTop: $("#aca").offset().top + $("#aca").height() / -10
            }, 1500);
        });
    }

    render() {
        return (
            <>
                <Title>Confían en nosotros</Title>
                <ContainerTestImage>
                        <ImageStyled src={logoPuertos} margin={true} />
                        <ImageStyled nordelta={true} src={logoNordelta} margin={true} />
                </ContainerTestImage>
            </>
        )
    }
}


export default groupImage
