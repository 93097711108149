import React from 'react'
import styled from 'styled-components'
import './carousel.css';
import './responsive.css';
import $ from 'jquery';
import celular from "../../../assets/celu.png";
import { Animated } from "react-animated-css";


const Container = styled.div`
    width:  ${(props) => props.widthLimit ? "90%" : "100%"};
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    margin: ${(props) => props.margin ? props.margin : "3% 0%"}; 
    
    @media (max-width: 850px) {
        flex-direction: column;
        width: 100%;        
        margin: 0px ; 
        position: ${(props) => props.relative ? "relative" : ""}; 
    }
`

const ContainerCol1 = styled.div`
    width:  ${(props) => props.widthLimit ? "90%" : "100%"};
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    margin: ${(props) => props.margin ? props.margin : "3% 0%"}; 
    
    @media (max-width: 850px) {
        display: none;
    }
`

const TitleDescriptionNOCSS = styled.div`
    width: 100%;
    height: 50px;
    font-size: 30px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.39;
    letter-spacing: normal;
    text-align: initial;
    color: ${(props) => props.color ? props.color : " #30735f"};
    
    @media (max-width: 850px) {
        /* display: none; */
        margin-bottom: 40px;
        text-align: center;    
    }
`
const ColumnTwo = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column; 
    @media (max-width: 850px) {
        width: 90%;
        margin: 0% 5%; 
    }
`

const ColumnTwoDescrip = styled.div`
    width: 100%;
    padding-top: 5%;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column; 
    @media (max-width: 850px) {
        width: 90%;
        margin: 0% 5%; 
        margin-bottom: 14%;
    }
`
const Img = styled.img`    
    z-index: 10000;
    width: 60%;
    margin-left: 20%;

     @media (max-width: 850px) {
        right: 1%;
        max-width: 16px;
        position: absolute;
        top: 20%;
        max-width: 20px;
    }

`
const TitleDescription = styled.div`
    width: 100%;
    height: 50px;
    font-size: 30px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.39;
    letter-spacing: normal;
    text-align: initial;
    color: ${(props) => props.color ? props.color : " #30735f"};
    border-left: 3px solid #30735f;
    padding-left: 12px;
    
    @media (max-width: 850px) {
        /* display: none; */
    }
`
const TitleDescriptionSlide = styled.div`
    width: 100%;
    height: 50px;
    font-size: 30px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.39;
    letter-spacing: normal;
    text-align: initial;
    color: ${(props) => props.color ? props.color : " #30735f"};

    -webkit-animation: tracking-in-expand-fwd-top 0.8s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
    animation: tracking-in-expand-fwd-top 0.8s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
    
    @media (max-width: 850px) {
        height: auto;
        font-size: 25px;
        margin: 4% 0%;
    }
`
const LabelDescription = styled.div`
    width: 100%;
    height: 90px;
    font-size: ${(props) => props.size ? props.size : "18px"};
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: initial;
    color: #4a4a4a;
    border-left: 3px solid #30735f;
    padding-left: 12px;
    margin-bottom: 20px; 

    -webkit-animation: tracking-in-expand-fwd-top 0.8s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
    animation: tracking-in-expand-fwd-top 0.8s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
    

    @media (max-width: 850px) {
        /* display: none; */
        height: auto;
    }
`


const LabelDescriptionSlide = styled.div`
    width: 100%;
    height: 90px;
    font-size: 18px;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: initial;
    color: #4a4a4a;
    margin-bottom: 10%; 
    @media (max-width: 850px) {
        height: auto;
    }
`

function moveToSelected(element) {
    if (element == "nextAdmin") {
        var selected = $(".selectedAdmin").next();
    } else if (element == "prevAdmin") {
        var selected = $(".selectedAdmin").prev();
    }

    var next = $(selected).next();
    var prev = $(selected).prev();
    var prevSecond = $(prev).prev();
    var nextSecond = $(next).next();

    $(selected).removeClass().addClass("selectedAdmin");

    $(prev).removeClass().addClass("prevAdmin");
    $(next).removeClass().addClass("nextAdmin");

    $(nextSecond).removeClass().addClass("nextRightSecondAdmin");
    $(prevSecond).removeClass().addClass("prevLeftSecondAdmin");

    $(nextSecond).nextAll().removeClass().addClass('hideRightAdmin');
    $(prevSecond).prevAll().removeClass().addClass('hideLeftAdmin');
}


class dataWhoAre extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            imageSlide: 1,
            txtReservas: true,
            txtProveedores: false,

        }
        this.escFunction = this.escFunction.bind(this);
    }

    escFunction(event) {
        if (event.keyCode === 37) {
            this.prev()
        }
        if (event.keyCode === 39) {
            this.next()
        }
    }
    componentDidMount() {
        document.addEventListener("keydown", this.escFunction, false);
    }
    componentWillUnmount() {
        document.removeEventListener("keydown", this.escFunction, false);
    }

    prev = () => {
        if (this.state.imageSlide > 0) {
            this.setState({ imageSlide: this.state.imageSlide - 1 });
            moveToSelected('prevAdmin')
        }
    }

    next = () => {
        if (this.state.imageSlide < 4) {
            this.setState({ imageSlide: this.state.imageSlide + 1 });
            moveToSelected('nextAdmin')
        }
    }

    toggleFalseAllTextos = () => {
        this.setState({
            txtCobroExpensas: false,
            txtReservas: false,
            txtProveedores: false,
            txtAccesos: false,
            txtComunicacion: false,
            txtPadron: false,
            txtBilletera: false,
            txtServicios: false,
            txtRoles: false,

        });
    }

    toggleExpensas = () => {
        this.toggleFalseAllTextos();
        this.setState({ txtCobroExpensas: true });
    }

    toggleProveedores = () => {
        this.toggleFalseAllTextos();
        this.setState({ txtProveedores: true });
    }

    toggleReservas = () => {
        this.toggleFalseAllTextos();
        this.setState({ txtReservas: true });
        this.next();
    }
    toggleAccesos = () => {
        this.toggleFalseAllTextos();
        this.setState({ txtAccesos: true });
        this.next();
    }

    toggleComunicacion = () => {
        this.toggleFalseAllTextos();
        this.setState({ txtComunicacion: true });
        this.next();
    }
    togglePadron = () => {
        this.toggleFalseAllTextos();
        this.setState({ txtPadron: true });
        this.next();
    }
    toggleBilletera = () => {
        this.toggleFalseAllTextos();
        this.setState({ txtBilletera: true });
        this.next();
    }
    toggleServicios = () => {
        this.toggleFalseAllTextos();
        this.setState({ txtServicios: true });
        this.next();
    }
    toggleRoles = () => {
        this.toggleFalseAllTextos();
        this.setState({ txtRoles: true });
        this.next();
    }
    render() {
        return (
            <Container id="aca" widthLimit={true} relative={true} >
                <ContainerCol1 padding={true} margin={"0% 5%"}>
                    <Img src={celular} onClick={() => this.next()} />
                </ContainerCol1>
                <ColumnTwoDescrip>
                    <TitleDescriptionNOCSS>¿Qué podés hacer con Miiii?</TitleDescriptionNOCSS>

                    <TitleDescription onMouseEnter={() => this.toggleReservas()}>Reservas</TitleDescription>
                    {this.state.txtReservas ?
                        <Animated animationIn="fadeIn" animationOut="fadeOut" animationInDuration={800} animationOutDuration={600} isVisible={this.state.txtReservas}>
                            <LabelDescription>Administrá los espacios y actividades disponibles y sus reservas</LabelDescription>
                        </Animated>
                        :
                        null
                    }
                    <TitleDescription onMouseEnter={() => this.toggleProveedores()}>Invitados</TitleDescription>
                    {this.state.txtProveedores ?
                        <Animated animationIn="fadeIn" animationOut="fadeOut" animationInDuration={800} animationOutDuration={600} isVisible={this.state.txtProveedores}>
                            <LabelDescription>Tené un registro de los datos de quienes ingresan a la comunidad, sean invitados o proveedores.</LabelDescription>
                        </Animated>
                        :
                        null
                    }
                    <TitleDescription onMouseEnter={() => this.toggleAccesos()}>Accesos</TitleDescription>
                    {this.state.txtAccesos ?
                        <Animated animationIn="fadeIn" animationOut="fadeOut" animationInDuration={800} animationOutDuration={600} isVisible={this.state.txtAccesos}>
                            <LabelDescription> El personal de seguridad, con una plataforma completa de control de accesos.</LabelDescription>
                        </Animated>
                        :
                        null
                    }
                    <TitleDescription onMouseEnter={() => this.toggleComunicacion()}>Comunicación</TitleDescription>
                    {this.state.txtComunicacion ?
                        <Animated animationIn="fadeIn" animationOut="fadeOut" animationInDuration={800} animationOutDuration={600} isVisible={this.state.txtComunicacion}>
                            <LabelDescription> Publicá noticias, eventos o circulares para toda la comunidad. Gestioná una ticketera de comunicación directa con cada unidad funcional.</LabelDescription>
                        </Animated>
                        :
                        null
                    }
                    <TitleDescription onMouseEnter={() => this.togglePadron()}>Padrón</TitleDescription>
                    {this.state.txtPadron ?
                        <Animated animationIn="fadeIn" animationOut="fadeOut" animationInDuration={800} animationOutDuration={600} isVisible={this.state.txtPadron}>
                            <LabelDescription> Lleva un registro de los habitantes de la comunidad agrupados por grupo familiar, con sus empleados, mascotas y vehículos.</LabelDescription>
                        </Animated>
                        :
                        null
                    }
                    <TitleDescription onMouseEnter={() => this.toggleExpensas()}>Expensas</TitleDescription>
                    {this.state.txtCobroExpensas ?
                        <Animated animationIn="fadeIn" animationOut="fadeOut" animationInDuration={800} animationOutDuration={600} isVisible={this.state.txtCobroExpensas}>
                            <LabelDescription>Conciliá automáticamente las expensas de cada mes. Eficientizá los procesos: cargá la liquidación, generá la cobranza, identificá los pagos y visualizá el estado de cada unidad.</LabelDescription>
                        </Animated>
                        :
                        null
                    }
                    <TitleDescription onMouseEnter={() => this.toggleBilletera()}>Billetera</TitleDescription>
                    {this.state.txtBilletera ?
                        <Animated animationIn="fadeIn" animationOut="fadeOut" animationInDuration={800} animationOutDuration={600} isVisible={this.state.txtBilletera}>
                            <LabelDescription size="15px"> Manejá las finanzas de cada comunidad más fácil. Cobrá, visualizá los movimientos, pagá a proveedores. Todo desde una plataforma segura y con cash in y cash out inmediato.</LabelDescription>
                        </Animated>
                        :
                        null
                    }
                    <TitleDescription onMouseEnter={() => this.toggleServicios()}>Servicios</TitleDescription>
                    {this.state.txtServicios ?
                        <Animated animationIn="fadeIn" animationOut="fadeOut" animationInDuration={800} animationOutDuration={600} isVisible={this.state.txtServicios}>
                            <LabelDescription> Llevá el control de los proveedores que ingresan a la comunidad. Asegurate de que tengan la documentación al día, y chequeá la calificación de los usuarios.</LabelDescription>
                        </Animated>
                        :
                        null
                    }
                    <TitleDescription onMouseEnter={() => this.toggleRoles()}>Roles</TitleDescription>
                    {this.state.txtRoles ?
                        <Animated animationIn="fadeIn" animationOut="fadeOut" animationInDuration={800} animationOutDuration={600} isVisible={this.state.txtRoles}>
                            <LabelDescription>Cada operador de la plataforma que trabaje con vos, con los roles y permisos que dispongas.</LabelDescription>
                        </Animated>
                        :
                        null
                    }
                </ColumnTwoDescrip>
            </Container>
        )
    }
}

export default dataWhoAre
