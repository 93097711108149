import React from 'react'
import styled from 'styled-components'
import { Imgstyled } from './header';
import $ from "jquery"
import admin from "../assets/admin.png"
import propietary from "../assets/propietary.png"
import proveedor from "../assets/proveedor.png"
import comercio from "../assets/comercio.png"
import adminMobile from "../assets/administradorMobile.png"
import propietaryMobile from "../assets/propietarioMobile.png"
import proveedorMobile from "../assets/proveedorMobile.png"
import comercioMobile from "../assets/comercioMobile.png"


export const Title = styled.div`
  width: 100%;
  height: 100px;
  font-size: 36px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.39;
  letter-spacing: normal;
  text-align: center;
  color: #30735f;
  margin-top: 3%;

    @media (max-width: 850px) {
        color: #30735f;
        font-size: 30px;
        height: 50px;
    }
`
export const Container = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    width: 90%;
    margin: 0% 6%;
    transition: all 1s ease-in-out;

    @media (max-width: 850px) {
        flex-direction: column;
        width: 100%;
        display: contents;
        margin: 0%;

    }
`
export const ImageStyled = styled.img`
    position: relative;
    width: 90%;     
    cursor: pointer;  
    margin-right: ${(props) => props.margin ? "15px" : ""};
    &:hover{
        transition: all 0.5s ease-in;
        transform: scale(1.1,1.1) ;
    }     
    @media (max-width: 850px) {
        display: none;
        
    }
`
const ImgStyledMobile = styled.img`
    display: none;

    @media (max-width: 850px) {
        display: block;
        margin: 17px;
        width: 72vh;
        height: 50vh;
        margin-top: 30px;
    }
`

export const Label = styled.div`
    position: absolute;
    bottom: 0px;
    left: 0;
    font-size: 25px;
    font-weight: 500;
    text-align: center;
    color: #ffffff;
    cursor: pointer;

    @media (max-width: 850px) {
        bottom: 40px;
        width: 100%;
        font-size: 20px;
        text-align: center;
        display: contents;
    }

`

export const ContainerImage = styled.div`
    position: relative;
    text-align:center;
    display:inline-block;
    transition: all 1s  ease-in-out;

`
export const TextMiddle = styled.div`
    position: absolute;
    width: 70%;
    top: 80%;
    left: ${(props) => props.left};
    text-align: center;
    cursor: pointer;

    &:hover ${Imgstyled}{
        transition: all 0.5s ease-in-out;
        transform: scale(1.1,1.1) ;
    } 
    @media (max-width: 850px) {
        top: 80%;
        width: 100%;
        left: 0px;
        font-size: 20px;
        text-align: center;
    }
`
const TitleDescription = styled.div`
    width: 100%;
    height: 50px;
    font-size: 30px;
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.39;
    letter-spacing: normal;
    text-align: center;
    color: ${(props) => props.color ? props.color : " #30735f"};
    margin: ${(props) => props.margin ? props.margin : ""};

    @media (min-width: 850px) {
        display: none;
    }
`



class groupImage extends React.Component {
    state = {
        active: "admin"

    }

    refreshActive = (active) => {
        this.setState({ active: active })
        this.props.handleHover(active)
        $(document).ready(function () {
            $("html, body").animate({
                scrollTop: $("#aca").offset().top + $("#aca").height() / -10
            }, 1500);
        });
    }

    render() {
        return (
            <>
                <Title>Seleccioná tu perfil</Title>
                <Container >
                    <ContainerImage onClick={() => this.refreshActive("admin")}>
                        <ImageStyled src={admin} margin={true} />
                        <ImgStyledMobile src={admin} margin={true} />
                        <TextMiddle left="25%">
                            <Label>Soy administrador</Label>
                        </TextMiddle>
                    </ContainerImage>

                    <ContainerImage onClick={() => this.refreshActive("propietario")}>
                        <ImageStyled src={propietary} />
                        <ImgStyledMobile src={propietary} />
                        <TextMiddle left="25%">
                            <Label>Soy propietario</Label>
                        </TextMiddle>
                    </ContainerImage>

                    <ContainerImage margin={true} onClick={() => this.refreshActive("proveedor")}>
                        <ImageStyled src={proveedor} />
                        <ImgStyledMobile src={proveedor} />
                        <TextMiddle left="25%">
                            <Label>Soy proveedor</Label>
                        </TextMiddle>
                    </ContainerImage>

                    <ContainerImage margin={true} onClick={() => this.refreshActive("comercio")}>
                        <ImageStyled src={comercio} />
                        <ImgStyledMobile src={comercio} />
                        <TextMiddle left="25%">
                            <Label>Soy Comercio</Label>
                        </TextMiddle>
                    </ContainerImage>

                    <TitleDescription id="whoare">
                        Todo lo que necesitás,
                    </TitleDescription>
                    <TitleDescription color={"#30735f"}>
                        en una sola app:
                    </TitleDescription>
                </Container>
            </>
        )
    }
}


export default groupImage
